.monday-error{
    max-width: 500px;
    margin: 0 auto;
    margin-top: 20px;
}
.form-builder-container {
    min-height: 100vh;
    width: 100%;
    padding: 50px 0;
    .form-builder-card {
        width: 800px;
        margin: 0 auto;
        @media screen and (max-width: 500px) {
            width: 100%;
        }
        .form-title-card {
            h1 {
                margin: 0;
                margin-bottom: 10px;
                padding: 0;
                font-size: 32px;
                font-weight: 600;
                line-height: 1;
                input {
                    width: 100%;
                    border: none;
                    outline: none;
                    font-size: 32px;
                    font-weight: 600;
                    line-height: 1;
                    margin: 0;
                    padding: 0;
                }
            }
            p {
                margin: 0;
                padding: 0;
                font-size: 16px;
                font-weight: 400;
                line-height: 1.5;
                input {
                    width: 100%;
                    border: none;
                    outline: none;
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 1.5;
                    margin: 0;
                    padding: 0;
                }
            }
        }
    }
}

.form-item-title {
    display: grid;
    height: 40px;
    grid-template-columns: 20px 1fr;
    align-items: center;
    gap: 10px;
    cursor: auto;
    .title-icon {
        width: 20px;
        height: 20px;
    }
}
.form-item-setting {
    display: flex;
    gap: 15px;
}
.form-item-description {
    .item-description {
        height: 54px;
        width: 100%;
        background-color: #f1f1f1;
        .placeholder {
            padding: 10px 15px;
            font-size: 14px;
            color: #b1b1b1;
            cursor: text;
        }
        .ant-input {
            border: none;
            outline: none;
            font-size: 14px;
            color: #b1b1b1;
            outline: none;
            box-shadow: none;
        }
    }
}
.form-button {
    height: 42px;
    width: 100%;
    outline: none;
    border: none;
    font-size: 16px;
    font-weight: 600;
    border-radius: 4px;
}
.form-item-conditional {
    display: flex;
    align-items: end;
    gap: 10px;
    justify-content: flex-end;
    // margin-top: 10px;
}

// form-layout style
.form-layout {
    // @media screen and (max-width: 500px) {
    //     width: 100%;
    // }
    // @media screen and (min-width: 1400px) {
    //     max-width: 1200px;
    // }
    background: #f1f1f1;
    .form-layout-setting {
        position: absolute;
        right: 10px;
        top: 10px;
        width: 200px;
        display: flex;
        gap: 10px;
    }
    .form-layout-vertical {
        max-width: 700px;
        margin: 0 auto;
        .form-layout-vertical-poster {
            padding: 20px;
            overflow: hidden;
            .form-layout-vertical-poster-title {
                min-height: 100px;
                display: flex;
                align-items: flex-start;
                justify-content: center;
                max-width: 400px;
                flex-direction: column;
                .form-header-content{
                    .form-layout-vertical-logo{
                        max-height: 120px;
                    }
                    h4{
                        margin-top: 0;
                    }
                }
            }
        }
        .form-layout-vertical-content {
            padding: 20px;
            background-color: #fff;
            border: 1px solid #f1f1f1;
            .form-layout-vertical-description {
                font-size: 14px;
                color: #777;
                margin-top: 0;
            }
        }
    }
    .form-layout-grid {
        min-height: 100vh;
        display: flex;
        align-items: center;
        justify-content: center;
        .form-layout-grid-description {
            font-size: 14px;
            color: #777;
            margin-top: 0;
        }
        .form-layout-grid-container {
            max-width: 1000px;
            margin: 0 auto;

            &.is--poster{
                display: flex;
                max-width: 1240px;
                min-width: 800px;
                .form-layout-grid-poster {
                    max-width: 400px;
                    width: 100%;
                    background-position: center !important;
                    background-size: cover !important;
                }
                .form-layout-grid-content {
                    flex: 2;
                    border-left: 1px solid #fff;
                    border-top: 1px solid #f1f1f1 !important;
                    .form-header-content{
                        margin-bottom: 30px;
                        border-bottom: 1px solid #f1f1f1;
                        .form-layout-grid-logo{
                            max-height: 120px;
                        }
                        h4{
                            margin-top: 0;
                        }
                    }
                }
            }
            &.is--color {
                display: flex;
                flex-direction: column;
                .form-layout-grid-poster {
                    width: 100%;
                    .form-layout-grid-poster-title {
                        &.color-panel {
                            padding: 30px 0;
                        }
                    }
                }
                .form-layout-grid-content {
                    width: 100%;
                }
            }
            .form-layout-grid-poster {
                padding: 20px;
                overflow: hidden;
                .form-layout-grid-poster-title{
                    .form-header-content{
                        .form-layout-grid-logo{
                            max-height: 120px;
                        }
                        h4{
                            margin-top: 0;
                        }
                    }
                }
            }
            .form-layout-grid-header {
                padding: 20px;
                background-color: #fff;
                border: 1px solid #f1f1f1;
            }
            .form-layout-grid-content {
                padding: 20px;
                background-color: #fff;
                border: 1px solid #f1f1f1;
                border-top: none;
                .form-layout-grid-poster-title {
                    border-bottom: 1px solid #f1f1f1;
                    margin-bottom: 20px;
                    padding-bottom: 20px;
                    .form-layout-grid-logo{
                        margin-bottom: 10px;
                    }
                    &.color-panel {
                        h4 {
                            font-size: 24px !important;
                        }
                    }
                }
            }
        }
    }
}
.form-setting-drawer{
    .form-layout-submit{
        position: obsolute;
        bottom: 0;
        left: 0;
        right: 0;
        text-align: center;
        padding: 10px;
        background-color: #fff;
        border-top: 1px solid #f1f1f1;
        width: 100%;
    }
}
